<template>
  <FormStructure :title="'Welcome to PMO system'" id="login">
    <template v-slot:form-content>
      <div>
        <InputText @input="setEmail" :input-type="'text'" :label="'Email'" @login ="doLogin()" />
      </div>

      <div class="relative">
        <InputText
          :input-type="showPassword ? 'text' : 'password'"
          :label="'Password'"
          :hasIcon="true"
          @input="setPassword"
          @login = "doLogin()"
        >
          <template v-slot:append-icon>
            <ShowPasswordIcon
              :show-password="showPassword"
              @toogle-password="showPassword = !showPassword"
            />
          </template>
        </InputText>
      </div>

      <div @click="doLogin()">
        <Button :btn-text="'Sign in'" />
      </div>

      <BottomLink :link-route="'/reset'" :text="'Forgot password?'" />
    </template>
  </FormStructure>
</template>
<script>
import Button from "/src/components/auth/Button.vue";
import InputText from "/src/components/auth/InputText.vue";
import ShowPasswordIcon from "/src/components/auth/ShowPasswordIcon.vue";
import BottomLink from "/src/components/auth/BottomLink.vue";
import FormStructure from "/src/components/auth/FormStructure.vue";
import { mapActions } from "vuex";
import Api from "/src/services/Apis";
export default {
  name: "Login",
  components: {
    Button,
    InputText,
    ShowPasswordIcon,
    BottomLink,
    FormStructure,
  },
  data: () => {
    return {
      showPassword: false,
      user: {
        email_or_username: "",
        password: "",
      },
    };
  },
  methods: {
    setEmail(value) {
      this.user.email_or_username = value;
    },
    setPassword(value) {
      this.user.password = value;
    },
    ...mapActions({ login: "auth/login" }),

    doLogin() {
      let { email_or_username, password } = this.user;

      const inputs = document.querySelectorAll("input");
      if ((email_or_username == "")) email_or_username = inputs[0].value;
      if ((password == "")) password = inputs[1].value;

      if (email_or_username != "" && password != "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Signing in ...",
          uptime: 3000,
        });
        return Api.login({ email: email_or_username, password }).then(
          (result) => {
            this.$store.commit("auth/loginSuccess", result.data);
            this.$router.push("/")
          }
        );
      } else {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "All fields are required",
          uptime: 3000,
          hasError: true,
        });
      }
    },
  },
};
</script>
<style lang="scss">
#login h1 {
  font-size: 28px !important;
  line-height: 34px !important;
}
</style>
